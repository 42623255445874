import citiesData from "./cities_data.json";

export type Location = {
  name: string;
  state: string;
  country: string;

  // booleans for easy filtering
  cleanAir: boolean;
  nearBeach: boolean;
  rural: boolean;
  town: boolean;
  city: boolean;
  affordableHousing: boolean;
  nearLargeCity: boolean;
  collegeEducated: boolean;
  asianPopulation: boolean;
  blackPopulation: boolean;
  hispanicPopulation: boolean;
  pacificIslanderPopulation: boolean;
  hasLakes: boolean;
  hasRiversCreeks: boolean;
  hasShopping: boolean;
  lowRent: boolean;
  notObese: boolean;
  lowUnemployment: boolean;
  rareNaturalDisasters: boolean;
  noEarthquakes: boolean;
  noTornadoes: boolean;
  noFires: boolean;
  noHurricanes: boolean;
  fewFloods: boolean;
  manySingles: boolean;
  lowCrime: boolean;
  manyForeigners: boolean;
  highPopulationGrowth: boolean;

  // raw source data
  airQualityIndex: number;
  populationTotal: number;
  populationMale: number;
  populationFemale: number;
  zipCodes: Array<String>;
  nearestCity200K: string;
  medianHouseholdIncome: string;
  medianHouseValue: string;
  medianRent: string;
  diversityBlack: string;
  diversityWhite: string;
  diversityMultiracial: string;
  diversityHispanic: string;
  diversityAsian: string;
  diversityPacificIslander: string;
  educationHighSchool: string;
  educationCollege: string;
  educationGraduate: string;
  beaches: Array<String>;
  lakes: Array<string>;
  riversCreeks: Array<string>;
  parks: Array<string>;
  shopping: Array<string>;

  obesity: string;
  unemployment: string;
  naturalDisastersTotal: number;
  statusNeverMarried: string;
  statusNowMarried: string;
  statusSeparated: string;
  statusWidowed: string;
  statusDivorced: string;
  crimeIndex: number;
  foreignBorn: string;
  populationChange: string;

  // TODO: natural disasters list
};

export type RaceStat = {
  count: number;
  percent: string;
};

// benchmarks for quality of life metrics
export const AIR_QUALITY_THRESHOLD: number = 80;
export const RURAL_SIZE: number = 6000;
export const TOWN_SIZE: number = 30000;
export const HOUSE_PRICE_TO_INCOME_RATIO: number = 3.5;
export const COLLEGE_EDUCATED_PERCENT: number = 60;
export const RACE_PRESENCE_PERCENT: number = 15;
export const LOW_RENT: number = 1000;
export const OBESITY_PERCENT: number = 35;
export const UNEMPLOYMENT_PERCENT: number = 4;
export const NATURAL_DISASTERS_AVERAGE: number = 13;
export const FEW_FLOODS: number = 4;
export const MANY_SINGLES: number = 50;
export const CRIME_INDEX_THRESHOLD: number = 274;
export const MANY_FOREIGNERS: number = 15;
export const HIGH_POPULATION_GROWTH: number = 15;

export const ALL_LOCATIONS: Array<Location> = citiesData.map(d => {
  return {
    name: d.name,
    state: d.state,
    country: "US",

    // booleans for easy filtering
    cleanAir:
      !!d.air_quality_index && d.air_quality_index < AIR_QUALITY_THRESHOLD,
    nearBeach: !!d.near_beach,
    rural: d.population_total < RURAL_SIZE,
    town: d.population_total >= RURAL_SIZE && d.population_total < TOWN_SIZE,
    city: d.population_total >= TOWN_SIZE,
    affordableHousing:
      !!d.median_house_value &&
      !!d.median_household_income &&
      isAffordableHousing(d.median_house_value, d.median_household_income),
    nearLargeCity: !!d.nearest_city_200k,
    collegeEducated:
      !!d.education_college &&
      parseFloat(d.education_college) > COLLEGE_EDUCATED_PERCENT,
    asianPopulation: !!d.diversity_asian && isRacePresent(d.diversity_asian),
    blackPopulation: !!d.diversity_black && isRacePresent(d.diversity_black),
    hispanicPopulation:
      !!d.diversity_hispanic && isRacePresent(d.diversity_hispanic),
    pacificIslanderPopulation:
      !!d.diversity_pacific_islander &&
      isRacePresent(d.diversity_pacific_islander),
    hasLakes: !!d.lakes && d.lakes.length > 0,
    hasRiversCreeks: !!d.rivers_creeks && d.rivers_creeks.length > 2,
    hasParks: !!d.parks && d.parks > 2,
    hasShopping: !!d.shopping && d.shopping.length > 2,
    lowRent:
      !!d.median_rent && convertDollarStringToNum(d.median_rent) < LOW_RENT,
    notObese:
      !!d.adult_obesity && parseFloat(d.adult_obesity) < OBESITY_PERCENT,
    lowUnemployment:
      !!d.unemployment && parseFloat(d.unemployment) < UNEMPLOYMENT_PERCENT,
    rareNaturalDisasters:
      !!d.natural_disasters_total &&
      d.natural_disasters_total < NATURAL_DISASTERS_AVERAGE,
    noEarthquakes: !d.natural_disasters_list.earthquakes,
    noTornadoes: !d.natural_disasters_list.tornadoes,
    noFires: !d.natural_disasters_list.fires,
    noHurricanes: !d.natural_disasters_list.hurricanes,
    fewFloods: d.natural_disasters_list.floods < FEW_FLOODS,
    manySingles:
      !!d.marital_status &&
      parseFloat(d.marital_status.now_married) < MANY_SINGLES,
    lowCrime: !!d.crime_index && d.crime_index < CRIME_INDEX_THRESHOLD,
    manyForeigners:
      !!d.foreign_born && parseFloat(d.foreign_born) > MANY_FOREIGNERS,
    highPopulationGrowth:
      !!d.population_change &&
      parseFloat(d.population_change) > HIGH_POPULATION_GROWTH,

    // raw source data
    airQualityIndex: d.air_quality_index || null,
    populationTotal: d.population_total || 0,
    populationMale: d.population_male || 0,
    populationFemale: d.population_female || 0,
    zipCodes: d.zip_codes || [],
    medianHouseholdIncome: d.median_household_income || "$0",
    medianHouseValue: d.median_house_value || "$0",
    medianRent: d.median_rent || "$0",
    nearestCity200K: d.nearest_city_200k
      ? `${d.nearest_city_200k.city}, ${d.nearest_city_200k.state}`
      : "",
    diversityBlack: d.diversity_black ? d.diversity_black.percent : "",
    diversityWhite: d.diversity_white ? d.diversity_white.percent : "",
    diversityMultiracial: d.diversity_multiracial
      ? d.diversity_multiracial.percent
      : "",
    diversityHispanic: d.diversity_hispanic ? d.diversity_hispanic.percent : "",
    diversityAsian: d.diversity_asian ? d.diversity_asian.percent : "",
    diversityPacificIslander: d.diversity_pacific_islander
      ? d.diversity_pacific_islander.percent
      : "",
    educationHighSchool: d.education_high_school || "",
    educationCollege: d.education_college || "",
    educationGraduate: d.education_graduate || "",
    beaches: d.beaches || [],
    lakes: d.lakes || [],
    riversCreeks: d.rivers_creeks || [],
    parks: d.parks || [],
    shopping: d.shopping || [],

    obesity: d.obesity || "",
    unemployment: d.unemployment || "",
    naturalDisastersTotal: d.natural_disasters_total || 0,
    statusNeverMarried: d.marital_status ? d.marital_status.never_married : "",
    statusNowMarried: d.marital_status ? d.marital_status.now_married : "",
    statusSeparated: d.marital_status ? d.marital_status.separated : "",
    statusWidowed: d.marital_status ? d.marital_status.widowed : "",
    statusDivorced: d.marital_status ? d.marital_status.divorced : "",
    crimeIndex: d.crime_index || 0,
    foreignBorn: d.foreign_born || "",
    populationChange: d.population_change || ""
  };
});

// helper functions to compute views over source data

// compute if race is substantially present in city
function isRacePresent(race: RaceStat): boolean {
  return parseFloat(race.percent) > RACE_PRESENCE_PERCENT;
}

// convert string dollar amount to integer number
function convertDollarStringToNum(amount: string): number {
  return parseInt(amount.replace(/[^\d.]/g, ""));
}

// compute ratio of median house value to median household income
function isAffordableHousing(houseValue: string, houseIncome: string): boolean {
  return (
    convertDollarStringToNum(houseValue) /
      convertDollarStringToNum(houseIncome) <
    HOUSE_PRICE_TO_INCOME_RATIO
  );
}
