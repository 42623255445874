import {
  Location,
  AIR_QUALITY_THRESHOLD,
  RURAL_SIZE,
  TOWN_SIZE,
  HOUSE_PRICE_TO_INCOME_RATIO,
  COLLEGE_EDUCATED_PERCENT,
  RACE_PRESENCE_PERCENT,
  LOW_RENT,
  OBESITY_PERCENT,
  UNEMPLOYMENT_PERCENT,
  NATURAL_DISASTERS_AVERAGE,
  FEW_FLOODS,
  MANY_SINGLES,
  CRIME_INDEX_THRESHOLD,
  MANY_FOREIGNERS,
  HIGH_POPULATION_GROWTH
} from "./location";
import Filter from "./LocationFilter";

export enum FilterType {
  Toggle,
  Range
}

export type Filter = {
  name: string;
  description: string;
  displayName: string;
  filterType: FilterType;
};

// apply active filters to locations
export function filterLocations(
  filters: Array<Filter>,
  locations: Array<Location>
): Array<Location> {
  return locations.filter(loc => {
    const filterKeys = filters.map(f => f.name);
    return filterKeys.every(f => {
      // returns true only if location matches all filters
      return loc[f];
    });
  });
}

export const ALL_FILTERS: Array<Filter> = [
  {
    name: "cleanAir",
    description: `Air Quality Index < ${AIR_QUALITY_THRESHOLD}`,
    displayName: "💨 Clean air",
    filterType: FilterType.Toggle
  },
  {
    name: "nearBeach",
    description: "< 1 hour from beach",
    displayName: "🏖️ Near beach",
    filterType: FilterType.Toggle
  },
  {
    name: "rural",
    description: `Population < ${RURAL_SIZE}`,
    displayName: "👨‍🌾 Rural",
    filterType: FilterType.Toggle
  },
  {
    name: "town",
    description: `${RURAL_SIZE} < population < ${TOWN_SIZE}`,
    displayName: "🏡 Town",
    filterType: FilterType.Toggle
  },
  {
    name: "city",
    description: `Population > ${TOWN_SIZE}`,
    displayName: "🏙️ City",
    filterType: FilterType.Toggle
  },
  {
    name: "affordableHousing",
    description: `Housing to income ratio < ${HOUSE_PRICE_TO_INCOME_RATIO}`,
    displayName: "🏡 Affordable housing",
    filterType: FilterType.Toggle
  },
  {
    name: "nearLargeCity",
    description: "Near city with > 200k population",
    displayName: "🌆 Near large city",
    filterType: FilterType.Toggle
  },
  {
    name: "collegeEducated",
    description: `>${COLLEGE_EDUCATED_PERCENT}% college educated >25 y/o`,
    displayName: "🎓 College educated",
    filterType: FilterType.Toggle
  },
  {
    name: "hasLakes",
    description: "Has 1 or more lakes in town",
    displayName: "🌊 Lakes",
    filterType: FilterType.Toggle
  },
  {
    name: "hasRiversCreeks",
    description: ">2 rivers, creeks, and streams",
    displayName: "🏞️ Rivers and creeks",
    filterType: FilterType.Toggle
  },
  {
    name: "hasParks",
    description: ">2 parks in town",
    displayName: "🌳 Parks",
    filterType: FilterType.Toggle
  },
  {
    name: "hasShopping",
    description: "3+ shopping centers in town",
    displayName: "🛍️ Shopping",
    filterType: FilterType.Toggle
  },
  {
    name: "lowRent",
    description: `Monthly rent and utilities < ${LOW_RENT}`,
    displayName: "💸 Low rent",
    filterType: FilterType.Toggle
  },
  {
    name: "notObese",
    description: `<${OBESITY_PERCENT}% of people have BMI > 30`,
    displayName: "⚖️ Not obese",
    filterType: FilterType.Toggle
  },
  {
    name: "lowUnemployment",
    description: `<${UNEMPLOYMENT_PERCENT}% of population unemployed`,
    displayName: "👨‍🍳 Low unemployment",
    filterType: FilterType.Toggle
  },
  {
    name: "rareNaturalDisasters",
    description: `<${NATURAL_DISASTERS_AVERAGE} disasters per year`,
    displayName: "🌨️ Rare natural disasters",
    filterType: FilterType.Toggle
  },
  {
    name: "noEarthquakes",
    description: "No earthquake activity",
    displayName: "🌎 No earthquakes",
    filterType: FilterType.Toggle
  },
  {
    name: "noTornadoes",
    description: "No tornado activity",
    displayName: "🌪️ No tornadoes",
    filterType: FilterType.Toggle
  },
  {
    name: "noFires",
    description: "No fire activity",
    displayName: "🔥 No fires",
    filterType: FilterType.Toggle
  },
  {
    name: "noHurricanes",
    description: "No hurricane activity",
    displayName: "⛈️ No hurricanes",
    filterType: FilterType.Toggle
  },
  {
    name: "fewFloods",
    description: `<${FEW_FLOODS} floods per year`,
    displayName: "☔ Few floods",
    filterType: FilterType.Toggle
  },
  {
    name: "manySingles",
    description: `<${MANY_SINGLES}% of population is married`,
    displayName: "❤️ Many singles",
    filterType: FilterType.Toggle
  },
  {
    name: "lowCrime",
    description: `Crime index < ${CRIME_INDEX_THRESHOLD} (US avg)`,
    displayName: "⚔️ Low crime",
    filterType: FilterType.Toggle
  },
  {
    name: "manyForeigners",
    description: `>${MANY_FOREIGNERS}% of foreign-born residents`,
    displayName: "✈️ Many foreigners",
    filterType: FilterType.Toggle
  },
  {
    name: "highPopulationGrowth",
    description: `>${HIGH_POPULATION_GROWTH}% population growth`,
    displayName: "👨‍👩‍👧‍👦 Population growth",
    filterType: FilterType.Toggle
  },
  {
    name: "asianPopulation",
    description: `>${RACE_PRESENCE_PERCENT}% Asian population`,
    displayName: `🐼 >${RACE_PRESENCE_PERCENT}% Asian`,
    filterType: FilterType.Toggle
  },
  {
    name: "blackPopulation",
    description: `>${RACE_PRESENCE_PERCENT}% Black population`,
    displayName: `🐻 >${RACE_PRESENCE_PERCENT}% Black`,
    filterType: FilterType.Toggle
  },
  {
    name: "hispanicPopulation",
    description: `>${RACE_PRESENCE_PERCENT}% Hispanic population`,
    displayName: `🐤 >${RACE_PRESENCE_PERCENT}% Hispanic`,
    filterType: FilterType.Toggle
  },
  {
    name: "pacificIslanderPopulation",
    description: `>${RACE_PRESENCE_PERCENT}% Pacific Islander population`,
    displayName: `🦁 >${RACE_PRESENCE_PERCENT}% Pacific Islander`,
    filterType: FilterType.Toggle
  }
];
