import React from "react";
import Modal from "react-modal";
import styled from "styled-components";

import LocationDetails from "./LocationDetails";

const Wrapper = styled.div`
  float: left;
  padding: 5px;
  width: 220px;

  &:hover {
    color: blue;
    cursor: pointer;
    text-decoration-line: underline;
  }
`;

const ModalCloseButton = styled.button`
  float: right;
`;

Modal.setAppElement(document.getElementById("root"));

export default function LocationGridItem({ location }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Wrapper onClick={openModal}>
      <div onClick={e => e.stopPropagation()}>
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
          <ModalCloseButton onClick={closeModal}>Close</ModalCloseButton>
          <LocationDetails location={location} />
        </Modal>
      </div>
      {location.name}, {location.state.replace("-", " ")}
    </Wrapper>
  );
}
