import React from "react";
import styled from "styled-components";

import LocationGridItem from "./LocationGridItem";

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding-top: 80px;
  padding-left: 480px;
`;

const MatchingLocations = styled.div``;

export default function LocationGrid({ locations }) {
  return (
    <Wrapper>
      <MatchingLocations>
        {locations.map(loc => (
          <LocationGridItem key={`${loc.name}-${loc.state}`} location={loc} />
        ))}
      </MatchingLocations>
    </Wrapper>
  );
}
