import React from "react";

import styled from "styled-components";

const Wrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;

  position: relative;
  top: -60px;
`;

const TooltipLabel = styled.div`
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export default function Tooltip({ content }) {
  return (
    <Wrapper>
      <TooltipLabel>{content}</TooltipLabel>
    </Wrapper>
  );
}
