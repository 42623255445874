import * as React from "react";
import "./App.css";
import ReactGA from "react-ga";

import AppHeader from "./AppHeader";
import { Location, ALL_LOCATIONS } from "./location";
import LocationFilters from "./LocationFilters";
import LocationGrid from "./LocationGrid";
import { Filter, FilterType, filterLocations } from "./filter";

type State = {
  // locations that match applied filters
  locations: Array<Location>;
  // applied filters
  filters: Array<Filter>;
};

type Action = {
  type: ActionType;
  payload?: any;
};

enum ActionType {
  ApplyFilter = "apply_filter",
  RemoveFilter = "remove_filter"
}

const Actions = {
  // apply a filter
  applyFilter: function(filter: Filter): Action {
    return {
      type: ActionType.ApplyFilter,
      payload: { filter }
    };
  },
  // remove a filter
  removeFilter: function(filter: Filter): Action {
    return {
      type: ActionType.RemoveFilter,
      payload: { filter }
    };
  }
};

const INITIAL_STATE: State = {
  filters: [],
  locations: ALL_LOCATIONS
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.ApplyFilter:
      return {
        ...INITIAL_STATE,
        ...applyFilter(state, action.payload.filter)
      };
    case ActionType.RemoveFilter:
      return {
        ...INITIAL_STATE,
        ...removeFilter(state, action.payload.filter)
      };
    default:
      return state;
  }
}

// returns new state with new filter applied and matching locations
function applyFilter(state: State, filter: Filter): Partial<State> {
  const updatedFilters = [...state.filters, filter];
  return {
    filters: updatedFilters,
    locations: filterLocations(updatedFilters, state.locations)
  };
}

// returns new state with filter removed and matching locations
function removeFilter(state: State, filter: Filter): Partial<State> {
  const updatedFilters = state.filters.filter(f => f !== filter);
  return {
    filters: updatedFilters,
    locations: filterLocations(updatedFilters, ALL_LOCATIONS)
  };
}

// google analytics
ReactGA.initialize("UA-142522554-7");

const App: React.FC = () => {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

  // handle user click on filter
  function onFilterClick(filter: Filter) {
    // toggle filter
    if (filter.filterType === FilterType.Toggle) {
      state.filters.includes(filter)
        ? dispatch(Actions.removeFilter(filter))
        : dispatch(Actions.applyFilter(filter));
    }
    // TODO: add other filter types
  }

  return (
    <div className="App">
      <AppHeader />
      <LocationFilters action={onFilterClick} filters={state.filters} />
      <LocationGrid locations={state.locations} />
    </div>
  );
};

export default App;
