import React, { useState } from "react";

import styled from "styled-components";
import Tooltip from "./Tooltip";

type ButtonProps = {
  isFilterActive: boolean;
};

const FilterButton = styled.button<ButtonProps>`
  background: ${props => (props.isFilterActive ? "#efefef" : "#fff")};
  border: 1px solid #efefef;
  border-radius: 3px;
  color: #494949;
  cursor: pointer;
  display: inline-block;
  font-family: Nunito, sans-serif;
  font-weight: 800;
  font-size: 1em;
  height: 50px;
  margin-bottom: 10px;
  margin-right: 1%;
  padding: 12px; /* Workaround to prevent tooltip moving button */
  text-decoration: none;
  width: 48%;
  &:hover {
    background-color: #f5f5f5;
  }
  &:focus {
    outline: 0;
  }
`;

export default function Filter({ filter, action, isActive }) {
  const [hover, setHover] = useState(false);
  const toggleHover = () => {
    setHover(!hover);
  };

  return (
    <FilterButton
      onClick={() => action(filter)}
      isFilterActive={isActive}
      onMouseOver={toggleHover}
      onMouseOut={toggleHover}
    >
      <span>{filter.displayName}</span>
      {hover ? <Tooltip content={filter.description} /> : null}
    </FilterButton>
  );
}
