import React from "react";

import styled from "styled-components";
import { CRIME_INDEX_THRESHOLD } from "./location";

const Wrapper = styled.div`
  padding: 20px;
`;

const CityState = styled.span`
  color: #ff6000;
  text-decoration-line: underline;
`;

const Detail = styled.div`
  padding: 10px;
`;

const DetailLabel = styled.span`
  font-weight: bold;
`;

const DetailValue = styled.span`
  margin-left: 5px;
`;

export default function LocationDetails({ location }) {
  return (
    <Wrapper>
      <h1>
        City stats:{" "}
        <CityState>
          {location.name}, {location.state}
        </CityState>
      </h1>
      <Detail>
        <DetailLabel>💨 Air Quality Index:</DetailLabel>
        <DetailValue>{location.airQualityIndex}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>👨‍👩‍👧‍👦 Total population:</DetailLabel>
        <DetailValue>{location.populationTotal}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🧔 Male population:</DetailLabel>
        <DetailValue>{location.populationMale}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>👩‍🦰 Female population:</DetailLabel>
        <DetailValue>{location.populationFemale}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>📍 Zip codes:</DetailLabel>
        <DetailValue>{location.zipCodes}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>💵 Median household income:</DetailLabel>
        <DetailValue>{location.medianHouseholdIncome}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🏡 Median house value:</DetailLabel>
        <DetailValue>{location.medianHouseValue}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🛋️ Median rent per month:</DetailLabel>
        <DetailValue>{location.medianRent}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🏙️ Nearest city with 200k population:</DetailLabel>
        <DetailValue>{location.nearestCity200K}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>😊 Black population:</DetailLabel>
        <DetailValue>{location.diversityBlack}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>😊 White population:</DetailLabel>
        <DetailValue>{location.diversityWhite}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>😊 Multiracial population:</DetailLabel>
        <DetailValue>{location.diversityMultiracial}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>😊 Hispanic population:</DetailLabel>
        <DetailValue>{location.diversityHispanic}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>😊 Asian population:</DetailLabel>
        <DetailValue>{location.diversityAsian}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>😊 Pacific Islander population:</DetailLabel>
        <DetailValue>{location.diversityPacificIslander}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🏫 People with high school education:</DetailLabel>
        <DetailValue>{location.educationHighSchool}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🏫 People with college education:</DetailLabel>
        <DetailValue>{location.educationCollege}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🏫 People with graduate degrees:</DetailLabel>
        <DetailValue>{location.educationGraduate}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🏖️ Beaches:</DetailLabel>
        <DetailValue>{location.beaches}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🌊 Lakes:</DetailLabel>
        <DetailValue>{location.lakes}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🏞️ Rivers, creeks, and streams:</DetailLabel>
        <DetailValue>{location.riversCreeks}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🌳 Parks:</DetailLabel>
        <DetailValue>{location.parks}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🛍️ Shopping:</DetailLabel>
        <DetailValue>{location.shopping}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>⚖️ Obesity rate:</DetailLabel>
        <DetailValue>{location.obesity}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>👨‍🍳 Unemployment rate:</DetailLabel>
        <DetailValue>{location.unemployment}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>🌨️ Average natural disasters per year:</DetailLabel>
        <DetailValue>{location.naturalDisastersTotal}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>💎 Never married:</DetailLabel>
        <DetailValue>{location.statusNeverMarried}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>💎 Currently married:</DetailLabel>
        <DetailValue>{location.statusNowMarried}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>💎 Currently separated:</DetailLabel>
        <DetailValue>{location.statusSeparated}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>💎 Currently divorced:</DetailLabel>
        <DetailValue>{location.statusDivorced}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>
          ⚔️ Crime index (US avg {CRIME_INDEX_THRESHOLD}):
        </DetailLabel>
        <DetailValue>{location.crimeIndex}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>✈️ Foreign born population:</DetailLabel>
        <DetailValue>{location.foreignBorn}</DetailValue>
      </Detail>
      <Detail>
        <DetailLabel>👪‍ Recent population change:</DetailLabel>
        <DetailValue>{location.populationChange}</DetailValue>
      </Detail>
    </Wrapper>
  );
}
