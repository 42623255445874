import React from "react";

import styled from "styled-components";

const HeaderRow = styled.div`
  background-color: #ffde32;
  border-bottom: 1px solid #d4bf52;
  font-weight: bold;
  height: 45px;
  left: 0;
  padding-top: 15px;
  position: fixed;
  top: 0;
  width: 100%;
`;

const HeaderTitle = styled.div`
  float: left;
`;

const Logo = styled.div`
  color: #ff6000;
  float: left;
  font-size: 1.2em;
  padding-top: 2px;
  width: 150px;
`;

export default function AppHeader() {
  return (
    <HeaderRow>
      <Logo>YOLO + FIRE</Logo>
      <HeaderTitle>
        <span>
          You Only Live Once 🚀 Be free, happy, & financially independent 🔥
          Find your next LCOL home today 🏡
        </span>
      </HeaderTitle>
    </HeaderRow>
  );
}
