import React from "react";
import styled from "styled-components";

import LocationFilter from "./LocationFilter";
import { ALL_FILTERS } from "./filter";

const Wrapper = styled.div`
  left: 0;
  height: 100vh !important;
  overflow-y: scroll;
  padding: 15px;
  position: fixed;
  top: 61px;
  width: 450px;
`;

export default function LocationFilters({ action, filters }) {
  return (
    <Wrapper>
      {ALL_FILTERS.map(filter => (
        <LocationFilter
          key={filter.name}
          filter={filter}
          action={action}
          isActive={filters.includes(filter)}
        />
      ))}
    </Wrapper>
  );
}
